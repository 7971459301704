/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Radio } from "antd";
import AppointmentDatePicker from "./AppointmentDatePicker";
import { getTimeSlots } from "../../apis/calender";
import "./AppointmentCalendar.css";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { LoadingSpinner } from "../General/Spinner/index.tsx";
import {isDatefrompast, isTimeRangeOverlap } from "../../Utils/common-utils";
import moment from 'moment-timezone';

const AppointmentCalendar = () => {
	const { state, updateState } = useContext(AppContext);
	const [calendarData, setCalendarData] = useState(null);
	const [timeSlots, setTimeSlots] = useState([]);
	const [timeLoading, setTimeLoading] = useState(false);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState([]);
	const timeSlotRef = useRef(null);

	function convertToAMPMWithoutSeconds(time) {
		const [hours, minutes] = time.split(":");
		let period = "AM";

		if (parseInt(hours) >= 12) {
			period = "PM";
		}

		const hours12 = parseInt(hours) % 12 || 12;

		return `${hours12}:${minutes} ${period}`;
	}

	function formatTimeRange(timeArray) {
		const formattedTimes = timeArray?.map((timeObj) => {
			const startTime = convertToAMPMWithoutSeconds(timeObj.start);
			const endTime = convertToAMPMWithoutSeconds(timeObj?.end);

			return {
				start: startTime,
				end: endTime,
				startUtc: timeObj?.startUtc,
				endUtc: timeObj?.endUtc,
			};
		});

		return formattedTimes;
	}

	const checkIfinputDateExist=(option)=>{
		let blockDate = new Date(`${option?.startUtc?.split("T")[0]}T00:00:00`)?.toString().substring(0, 10)
		let blockbyadmin = state?.organizationDetails?.holidays?.filter((dateInfo) => {
			const date = new Date(dateInfo?.date)?.toString().substring(0, 10)
			return date === blockDate
		})[0]
		return blockbyadmin
	}

	const handleTimeSlot = (slots) => {
		let count = 0;
		const formattedTimeSlots = formatTimeRange(slots);
		let updatedSlots = formattedTimeSlots;
	
		if (!formattedTimeSlots) {
			setTimeSlots(formattedTimeSlots);
			return;
		}
		const timeZone = state.organizationDetails?.organization_timeZone || "UTC"
		const currentDate = moment.tz(timeZone).startOf('day');
		const slotEndDate = moment.tz(formattedTimeSlots[0].startUtc, timeZone).startOf('day');
		const isSameDate = currentDate.isSame(slotEndDate, 'day');
		console.log(isSameDate)
		if (isSameDate) {
			updatedSlots = formattedTimeSlots?.filter((slot) => isDatefrompast(slot,timeZone)) || [];
		}
	
		const isalldayBlock = updatedSlots?.map((option) => {
			let blockbyadmin = checkIfinputDateExist(option);
			let formattedDateRange = `${option?.start} - ${option?.end}`;
			let isOverlap = isTimeRangeOverlap(formattedDateRange, blockbyadmin?.TimeRange);
			if (blockbyadmin?.BlockAllday || isOverlap) {
				count += 1;
			}
		});
	
		if (count === isalldayBlock?.length) {
			setTimeSlots(null);
		} else {
			setTimeSlots(updatedSlots);
		}
	};

	useEffect(() => {
		setSelectedTimeSlot(null);
		updateState({
			...state,
			isSubStepValid: false,
			miscellaneous: {
				...state.miscellaneous,
				loading: true,
				appointmentReviewPage: false,
			},
		});

		// if no business unit, then do not send timeslot call

		// if (state?.additionalInfo?.jobItemToBook?.st_business_unit_id?.length) fetchTimeSlots();
		fetchTimeSlots();
	}, []);

	const fetchTimeSlots = async () => {
		try {
			setTimeLoading(true);

			const payload = {
				organizationId: state?.organizationDetails?.id,
				// businessUnit: "129",
				businessUnit: state?.additionalInfo?.jobItemToBook?.st_business_unit_id
					?.length
					? state?.additionalInfo?.jobItemToBook?.st_business_unit_id
					: "",
			};

			let res = await getTimeSlots(payload);
			setCalendarData(res);
		} catch (err) {
			console.log(err);
		} finally {
			setTimeLoading(false);
			updateState({
				...state,
				isSubStepValid: false,
				miscellaneous: {
					...state.miscellaneous,
					loading: false,
				},
			});
		}
	};

	useEffect(() => {
		if (selectedTimeSlot) {
			updateState({
				...state,
				isSubStepValid: true,
			});
		}
	}, [selectedTimeSlot]);

	return (
		<div className="appointment-date-picker">
			{timeLoading && (
				<div className="spinner-container">
					<LoadingSpinner />
				</div>
			)}

			<div className="AppointmentCalendar_otr">
				<AppointmentDatePicker
					calendarData={calendarData}
					onSelectedDate={handleTimeSlot}
					setSelectedTimeSlot={setSelectedTimeSlot}
					timeSlotRef={timeSlotRef}
				/>
				<div id="timeSlots" ref={timeSlotRef} className="date_radio_otr">
					{timeSlots ? (
						<>
							<p className="date_text">
								Please select one of the available time slots
							</p>
							<div className="date_check_group_wrapper">
								<Radio.Group
									className="radio_check_group"
									onChange={(e) => {
										setSelectedTimeSlot(e.target.value);
										updateState({
											...state,
											appointment: {
												...state.appointment,
												arrivalWindowStart: e?.target.value?.startUtc,
												arrivalWindowEnd: e?.target.value?.endUtc,
												displayStartTime: e?.target?.value?.start,
												displayEndTime: e?.target?.value?.end,
											},
										});
									}}
									value={selectedTimeSlot}
								>
									{timeSlots?.map((option) => {
										let blockbyadmin = checkIfinputDateExist(option)
										let formattedDateRange= `${option?.start} - ${option?.end}`
										let isDateBlocked = isTimeRangeOverlap(formattedDateRange, blockbyadmin?.TimeRange)
										if(!isDateBlocked) {
											return (
												<div className="time_slot_radio_btn_wrapper" key={`${formattedDateRange}`}>
													<Radio value={option}>
														{formattedDateRange}
													</Radio>
												</div>
											)
										}
										else{
											return null
										}
									})}
								</Radio.Group>
							</div>
						</>
					) : (
						<span className="noslots_response">
							There are no openings remaining for this date. Please select a
							different date, or call us if this is an emergency.
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppointmentCalendar;
