import React, { useContext, useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { PrimaryInput } from "../../General/Input/index.tsx";
import StateSelect from "./StateSelect.jsx";
import { Checkbox } from "antd";
import { LoadingSpinner } from "../../General/Spinner/index.tsx";
import "./NewCustomer.css";
import { AppContext } from "../../../Context/AppContext.js";

const AddressStep = () => {
	const { state, updateState } = useContext(AppContext);
	const [isPrefilled, setIsPrefilled] = useState(false);
	const [ownResidence, setOwnResidence] = useState(state?.miscellaneous?.residenceCheckbox);

	const handleAutoFillAddress = (place) => {
		const streetNumber = place?.address_components.find((component) =>
			component.types.includes("street_number")
		)?.long_name;
		const route = place?.address_components.find((component) =>
			component.types.includes("route")
		)?.long_name;
		const street =
			route && streetNumber
				? `${streetNumber} ${route}`
				: route || streetNumber;

		const postalCode = place?.address_components.find((component) =>
			component.types.includes("postal_code")
		)?.long_name;
		const locality = place?.address_components.find((component) =>
			component.types.includes("locality")
		)?.long_name;
		const stateAbbreviation = place?.address_components.find((component) =>
			component.types.includes("administrative_area_level_1")
		)?.short_name;

		const newInputErrors = {};

		if (!street) {
			newInputErrors.street = "Street is required";
		}

		if (!locality) {
			newInputErrors.city = "City is required";
		}

		if (!stateAbbreviation) {
			newInputErrors.state = "State is required";
		}

		if (!postalCode) {
			newInputErrors.zip = "Zip code is required";
		}

		setInputErrors(newInputErrors);

		
		validateZipCode(postalCode || "");

		updateState({
			...state,
			miscellaneous: {
				...state.miscellaneous,
				newCustomerPagewhatconverts:false,
				newCustomerData: {
					...state.miscellaneous.newCustomerData,
					street: street || "",
					city: locality || "",
					state: stateAbbreviation || null,
					zip: postalCode || "",
				},
			},
		});
	};

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: false,
			miscellaneous: {
				...state.miscellaneous,
				newCustomerPage: true,
				newCustomerPagewhatconverts:false,
			},
		});

		const {newCustomerData} = state.miscellaneous;

		const checkPrefilledData = Object.values(newCustomerData || {}
		).every((value) => value !== "");
		setIsPrefilled(checkPrefilledData);

		if (checkPrefilledData) {
			const zipCode = newCustomerData.zip || "";
			const valid = zipCode.length ? validateZipCode(zipCode) : true;
	
			updateState({
				...state,
				miscellaneous: {
					...state.miscellaneous,
					// newCustomerPage: false, uncomment later
				},
				isSubStepValid: valid,
			});
		} else {
			updateState({
				...state,
				isSubStepValid: false,
			});
		}
	}, []);

	const [inputErrors, setInputErrors] = useState({
		street: "",
		city: "",
		state: "",
		zip: "",
	});

	const validateField = (fieldName, value, labelText) => {
		if (fieldName === "city" && value) {
			if (!/^[A-Za-z]+$/.test(value.trim())) {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					[fieldName]: `${labelText} must contain letters only`,
				}));
				return false;
			}
		}

		if (!value || value.trim() === "") {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: `${labelText} is required`,
			}));
			return false;
		} else {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: "",
			}));
			return true;
		}
	};

	const validateZipCode = (zip) => {
		let valid = true;
		if (zip !== "" && zip?.trim().length === 5) {
			const isValidZip = state?.organizationDetails?.zipCodes.includes(zip);
			if (!isValidZip) {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					zip: "Sorry, we do not serve this area. Please call if you feel this is an error.",
				}));
				valid = false;
			} else {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					zip: "",
				}));
			}
		} else if(!zip?.trim().length) {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				zip: "Zip code is required",
			}));
			valid = false;
		}else if(zip?.length < 5) {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				zip: "Minimum Zip code length is 5 characters",
			}));
		}

		return valid;
	};

	const handleBlurChange = (fieldName, value, labelText) => {
		if (!value) {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: `${labelText} is required`,
			}));
		}
	};

	const handleInputChange = (field, value, labelText) => {
		updateState({
			...state,
			miscellaneous: {
				...state.miscellaneous,
				newCustomerData: {
					...state.miscellaneous.newCustomerData,
					[field]: value,
				},
			},
		});

		validateField(field, value, labelText);
	};

	const handleZipCodeChange = (zip) => {
		zip = zip.slice(0, 5);
		updateState({
			...state,
			miscellaneous: {
				...state.miscellaneous,
				newCustomerData: {
					...state.miscellaneous.newCustomerData,
					zip,
				},
			},
		});

		validateZipCode(zip);
	};

	useEffect(() => {
		const isFormValid =
			Object.values(inputErrors || {}).every((value) => value === "") &&
			Object.values(state?.miscellaneous?.newCustomerData || {}).every(
				(value) => value !== ""
			);
		updateState({
			...state,
			isSubStepValid: isFormValid,
			miscellaneous: {
				...state.miscellaneous,
				newCustomerPage: isFormValid,
			},
		});
	}, [inputErrors, state.miscellaneous.newCustomerData]);

	const handleResidenceChange = (residenceValue) => {
		setOwnResidence(residenceValue);
		updateState({
			...state,
			miscellaneous: {
				...state.miscellaneous,
				residenceCheckbox: residenceValue,
			},
		});
	}

	return (
		<>
			{state.miscellaneous.loading ? (
				<div className="spinner-container">
					<LoadingSpinner />
				</div>
			) : (
				<div className="steps_content_inr AddressStep_otr">
					<h6 className="customer_heading">
						Address where our team is needed
						<hr />
					</h6>
					<p className="customer_desc">Please search for your address below</p>

					<div className="address_input_otr">
						<div className="input_otr">
							<ReactGoogleAutocomplete
								apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
								onPlaceSelected={(place) => handleAutoFillAddress(place)}
								className="address-autofill"
								options={{
									componentRestrictions: { country: "us" },
									fields: [
										"address_components",
										"formatted_address",
										"geometry",
										"name",
									],
									strictBounds: false,
									types: ["address"],
								}}
							/>
						</div>
						{state?.miscellaneous?.error && (
							<div className="error-message">{state?.miscellaneous?.error}</div>
						)}
						<div className="or_text_otr">
							<hr />
							<span className="or_text">OR</span>
							<hr />
						</div>
					</div>
					<p className="customer_desc">
						If your address is not found, please enter it below
					</p>
					<div className="address_inputs_otr">
						<PrimaryInput
							className={`theme_input ${
								inputErrors?.street ? "error-border" : ""
							}`}
							placeholder="Street Address"
							labelText="Street Address"
							value={state?.miscellaneous?.newCustomerData?.street}
							onBlur={(value) =>
								handleBlurChange("street", value, "Street Address")
							}
							onChange={(value) =>
								handleInputChange("street", value, "Street Address")
							}
							error={inputErrors?.street}
						/>

						<PrimaryInput
							className={`theme_input ${
								inputErrors?.apartment ? "error-border" : ""
							}`}
							placeholder="Apartment / Unit"
							labelText="Apartment / Unit"
							value={state?.miscellaneous?.newCustomerData?.apartment}
							onChange={(value) =>
								handleInputChange("apartment", value, "Apartment")
							}
							error={inputErrors?.apartment}
						/>

						<PrimaryInput
							className={`theme_input ${
								inputErrors?.city ? "error-border" : ""
							}`}
							placeholder="City"
							labelText="City"
							value={state?.miscellaneous?.newCustomerData?.city}
							onBlur={(value) => handleBlurChange("city", value, "City")}
							onChange={(value) => handleInputChange("city", value, "City")}
							error={inputErrors?.city}
						/>

						<StateSelect
							value={state?.miscellaneous?.newCustomerData?.state}
							onChange={(value) => handleInputChange("state", value, "State")}
							labelText="State"
						/>

						<PrimaryInput
							className={`theme_input ${
								inputErrors?.zip ? "error-border" : ""
							}`}
							placeholder="Zip Code"
							labelText="Zip Code"
							value={state?.miscellaneous?.newCustomerData?.zip}
							onBlur={(value) => handleBlurChange("zip", value, "Zip code")}
							onChange={(value) => handleZipCodeChange(value)}
							error={inputErrors?.zip}
						/>

						<Checkbox
							checked={ownResidence}
							onChange={(e) => handleResidenceChange(e.target.checked)}
							className="theme_checkbox"
							style={{ display: "flex", alignItems: "center" }}
						>
							<span>I own this residence</span>
						</Checkbox>
					</div>
				</div>
			)}
		</>
	);
};

export default AddressStep;