import {Steps} from 'antd'
import { SchedulerSteps } from '../../../Utils/constants'

import './Stepper.css'

const Stepper = ({currentStep}) => {
  return (
    <div className='appointment_stepper_wrapper'>
      <Steps
        className='appointmnt_setps'
        current={currentStep}
        labelPlacement="vertical"
        items={SchedulerSteps}
      />
    </div>
  )
}

export default Stepper;