/* eslint-disable react-hooks/exhaustive-deps */
import AdditionalDescription from "./AdditionalDescription";
import DiagnosticFee from "./DiagnosticFee";

import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import AdditionalQuestions from "./Questions/AdditionalQuestions";

const Description = ({ currentSubStep, setTotalSteps, setCurrentSubStep,setCurrentMainStep,iscustomUrl=false }) => {
  const { state, updateState  } = useContext(AppContext);
  const [components, setComponents] = useState(0);

  const InternalComponents = {
    0: [
      {
        component: (
          <AdditionalQuestions
            currentSubStep={currentSubStep}
            setComponents={setComponents}
            setCurrentSubStep={setCurrentSubStep}
          />
        ),
      },
      {
        component: <DiagnosticFee />,
      },
      {
        component: <AdditionalDescription />,
      },
    ],
    1: [
      {
        component: (
          <AdditionalQuestions
            currentSubStep={currentSubStep}
            setComponents={setComponents}
            setCurrentSubStep={setCurrentSubStep}
            setCurrentMainStep={setCurrentMainStep}
          />
        ),
      },
      {
        component: <AdditionalDescription />,
      },
    ],
    2: [
      {
        component: <DiagnosticFee />,
      },
      {
        component: <AdditionalDescription  
        currentSubStep={currentSubStep}
        setCurrentSubStep={setCurrentSubStep} />,
      },
    ],
    3: [
      {
        component: <AdditionalDescription />,
      },
    ],
  };

  const paramsInternalComponents = {
    0: [
      {
        component: <DiagnosticFee />,
      },
      {
        component: <AdditionalDescription
        currentSubStep={currentSubStep}
        setCurrentSubStep={setCurrentSubStep}
        setCurrentMainStep={setCurrentMainStep}
         />,
      },
    ],
    1: [
      {
        component: <AdditionalDescription 
        currentSubStep={currentSubStep}
        setCurrentSubStep={setCurrentSubStep}
        setCurrentMainStep={setCurrentMainStep}
        />,
      },
    ],
    2: [
      {
        component: <DiagnosticFee />,
      },
      {
        component: <AdditionalDescription
        currentSubStep={currentSubStep}
        setCurrentSubStep={setCurrentSubStep}
        setCurrentMainStep={setCurrentMainStep}
         />,
      },
    ],
    3: [
      {
        component: <AdditionalDescription
        currentSubStep={currentSubStep}
        setCurrentSubStep={setCurrentSubStep}
        setCurrentMainStep={setCurrentMainStep}
         />,
      },
    ],
  }

  useEffect(() => {
    let newComponents = 0;
    const isJob = state?.problem?.jobItem?.is_job;
    const hasAdditionalQuestions =
      state?.miscellaneous?.additionalQuestions?.length > 0;
    console.log("state at description",state)
    let dispatchFee = state.clientInfo.is_member==='false'?state?.additionalInfo?.jobItemToBook?.dispatch_fee:state?.additionalInfo?.jobItemToBook?.member_dispatch_fee ?? 0;
    console.log("dispatch feee")
    console.log(state?.customUrlData?.dispatchFee)
    if(state?.customUrlData?.dispatchFee){
      dispatchFee = state.customUrlData.dispatchFeeDetails.member_is_active?state.customUrlData.dispatchFeeDetails.member_dispatch_fee:state.customUrlData.dispatchFeeDetails.dispatch_fee;  
    }

    if (isJob && !hasAdditionalQuestions) {
      if (!dispatchFee || dispatchFee < 1) {
        newComponents = 3;
      } else {
        newComponents = 2;
      }
    }

    if (isJob && hasAdditionalQuestions) {
      if (!dispatchFee || dispatchFee < 1) {
        newComponents = 1;
      } else {
        newComponents = 0;
      }
    }

    if (!isJob) {
      if (dispatchFee === undefined || dispatchFee < 1) {
        newComponents = 1;
      } else {
        newComponents = 0;
      }
    }

    setComponents(newComponents);
  }, [state?.additionalInfo?.jobItemToBook]);

  useEffect(() => {
    const totalSteps = iscustomUrl?paramsInternalComponents[components]?.length || 0 :InternalComponents[components]?.length || 0;
    setTotalSteps((prev) => {
      return {
        ...prev,
        description: { ...prev.description, totalSteps: totalSteps - 1 },
      };
    });
  }, [components]);

  return (
    <div className="steps_content_inr">
      { iscustomUrl?paramsInternalComponents[components]?.[currentSubStep]?.component:InternalComponents[components]?.[currentSubStep]?.component}
    </div>
  );
};

export default Description;
