import { Input } from "antd";
import "./Description.css";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../Context/AppContext";
const { TextArea } = Input;

const AdditionalDescription = () => {
	const { state, updateState } = useContext(AppContext);

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: true,
		});
	}, []);

	return (
		<div className="AdditionalDescription_otr">
			<div className="bread_crumbs">
				<p className="Service_text">
					{state?.problem?.trade?.trade?.name} - {state?.problem?.servicetype} -{" "}
					{state?.problem?.jobItem?.category_name}
				</p>
			</div>
			<div className="content_textarea">
				<h6 className="header_heading">
					Additional information
					<hr />
				</h6>
				<p className="desc">
					Please enter any additional details you'd like to share about your
					needs or equipment.
				</p>
				<TextArea
					className="text_area"
					placeholder="Issue description"
					value={state?.additionalInfo?.additionalInfo}
					onChange={(e) =>
						updateState({
							...state,
							additionalInfo: {
								...state.additionalInfo,
								additionalInfo: e.target.value,
							},
						})
					}
				/>
			</div>
		</div>
	);
};

export default AdditionalDescription;
