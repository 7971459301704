import axios from "./axios";

export const getOrganizationDetails = async (websiteURL) => {
	try {
		const url = websiteURL;
		const res = await axios.get(`/org/fetch/details`, {
			params: { url },
		});

		return res || "";
	} catch (e) {
		throw e;
	}
};
