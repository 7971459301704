import moment from "moment-timezone";

export const cloneDeep = (value) => {
    return JSON.parse(JSON.stringify(value));
};

export function isTimeRangeOverlap(range1, range2, allDay) {
    if (!range2 || !range1) {
    return false;
    }
  const range1Start = range1?.split("-")[0];
  const range1End = range1?.split("-")[1];
  const range2Start = range2?.split("-")[0];
  const range2End = range2?.split("-")[1];
    const range1StartDate = new Date(`01/01/2000 ${range1Start}`);
    const range1EndDate = new Date(`01/01/2000 ${range1End}`);
    const range2StartDate = new Date(`01/01/2000 ${range2Start}`);
    const range2EndDate = new Date(`01/01/2000 ${range2End}`);
    if (
        (range1StartDate < range2EndDate && range1EndDate > range2StartDate) ||
        (range2StartDate < range1EndDate && range2EndDate > range1StartDate)
    ) {
        return true;
    } else {
        return false;
    }
}

export function getTimeInMinutes(time) {
    const [hours, minutes] = time.split(/[:\s]/).map(Number);
    const period = time.match(/[AP]M/i);
    const isPM = period && period[0].toUpperCase() === 'PM';
    const totalMinutes = ((hours % 12) + (isPM ? 12 : 0)) * 60 + minutes;
    return totalMinutes;
}

export function formatTime(date) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hours >= 12;
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const period = isPM ? "PM" : "AM";
    return `${hours}:${formattedMinutes} ${period}`;
}

export function isTimeSlotInPast(slot, currentMinutes) {
    if (!slot) {
        return false;
    }
    const slotEndTime = slot.end;
    const slotEndMinutes = getTimeInMinutes(slotEndTime);
    return slotEndMinutes < currentMinutes;
}

function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
}

// export function isDatefrompast(slot){
// const date = new Date();
// const formattedSlotDate= slot?.startUtc?.split("T")[0].replace("-","/")
// const slotDate=new Date(Date.parse(`${formattedSlotDate} ${slot.end}`));

// if(slotDate>date){
//     return true
// }
// else{
//     return false
// }
// }

// export function isDatefrompast(slot, timeZones) {
//     console.log(Array.isArray(timeZones))
//   if (Array.isArray(timeZones)) {
//     let isAfter=0
//     timeZones.forEach((element) => {
//       const currentDate = moment.tz(element);
//       const slotEndDate = moment.tz(slot.endUtc, element);
//       if (slotEndDate.isAfter(currentDate)) {
//         isAfter=isAfter+1
//       }
//     });
//     if (isAfter > 0) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     const currentDate = moment.tz(timeZones);
//     const slotEndDate = moment.tz(slot.endUtc, timeZones);

//     if (slotEndDate.isAfter(currentDate)) {
//       return true;
//     } else {
//       return false;
//     }
//   }
// }

export function filterpastDates(date, timeZone) {
  const inputDate = moment(date).tz(timeZone).startOf('day').format();
  const currentDate = moment().tz(timeZone).startOf('day').format()
  
  return inputDate < currentDate
}

export function isDatefrompast(slot, timeZone) {
    const currentDate = moment().tz(timeZone).format();
    const slotStartDate = moment.tz(slot.startUtc, timeZone).set({
      hour: moment(slot.start, 'hh:mm A').hour(),
      minute: moment(slot.start, 'hh:mm A').minute(),
      second: 0,
      millisecond: 0
    }).format();
    console.log("currentDate", currentDate)
    console.log("slotEndDate",slotStartDate )
    return slotStartDate  > currentDate;
}

