import { Button } from "antd";
import { createCustomer, createCustomerwhatconverts } from "../../../apis/customer";
import { createBooking } from "../../../apis/booking";
import "../Stepper/Stepper.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/AppContext";
import { LoadingSpinner } from "../Spinner/index.tsx";


const Navigation = ({
  currentMainStep,
  currentSubStep,
  setCurrentMainStep,
  totalSteps,
  mainStepComponents,
  setCurrentSubStep,
}) => {
  const { state, updateState, resetAdditionalInfo } = useContext(AppContext);
  const [loading, setloading] = useState(false);

  const clearStates = () => {
    if (currentMainStep === 2 && currentSubStep === 1) {
      resetAdditionalInfo();
    }
  };

  const goToPreviousMainStep = () => {
    const mainStepCount = mainStepComponents[currentMainStep - 1];
    const steps = totalSteps[mainStepCount?.name]?.totalSteps;

    setCurrentSubStep(steps);
    setCurrentMainStep(currentMainStep - 1);
  };

  const handleBackButton = () => {
    clearStates();
    updateState({
      ...state,
      next:false,
      back:true
    })

    if (
      currentMainStep === 1 &&
      currentSubStep === 1 &&
      state.problem.isSingleTrade
    ) {
      goToPreviousMainStep();
    } else if (currentSubStep > 0) {
      setCurrentSubStep(currentSubStep - 1);
    } else if (currentSubStep === 0 && currentMainStep === 0) {
      setCurrentSubStep(null);
    } else if (currentSubStep === 0 && currentMainStep > 0) {
      goToPreviousMainStep();
    }
  };
  const handleNextbutton = async () => {
    updateState({
      ...state,
      next:true,
      back:false
    })
    if(state.miscellaneous.newCustomerPagewhatconverts){
      try {
        await handleNewCustomerWhatconverts(state.miscellaneous.newCustomerData)
      } catch (err) {
        updateState({
          ...state,
          isSubStepValid: true,
          miscellaneous: {
            ...state.miscellaneous,
            loading: false,
            error: err?.response
              ? err?.response?.data?.message
              : "Could not add customer to whatconverts, please try again later.",
          },
        });
        setloading(false)
        return;
      }
    }
    if (state.miscellaneous.newCustomerPage) {
      try {
        const res = await handleNewCustomerCall(
          state.miscellaneous.newCustomerData
        );
      } catch (err) {
        updateState({
          ...state,
          isSubStepValid: true,
          miscellaneous: {
            ...state.miscellaneous,
            loading: false,
            error: err?.response
              ? err?.response?.data?.message
              : "Could not add customer, please try again later.",
          },
        });
        setloading(false)
        return;
      }
    }
    const mainStepCount = mainStepComponents[currentMainStep];
    const totalComponentSteps = totalSteps[mainStepCount?.name]?.totalSteps;
    if ((state?.customUrlData?currentMainStep < 3 :currentMainStep < 4)) {
      if (currentSubStep === totalComponentSteps) {
        setCurrentMainStep(currentMainStep + 1);
        setCurrentSubStep(0);
      } else {
        setCurrentSubStep(currentSubStep + 1);
      }
    }
  };

  const handleNewCustomerWhatconverts = async (customerDetails) => {
    try {
      setloading(true)
      const payload = {
        ...customerDetails,
        phone: customerDetails?.phone?.replace(/\s/g, ""),
        event_id: state.event_id,
        cookie: state.cookie || "",
        organizationId: state.organizationDetails?.id,
      };
      let res =   await createCustomerwhatconverts(
       payload
      );  
      updateState({
        ...state,
        miscellaneous: {
          ...state?.miscellaneous,
          newCustomerPage: false,
          error: "",
        },
        clientInfo: {
          ...state.clientInfo,
          isExistingCustomer: false,
          mobilephone: payload?.phone,
          customer_id: res?.id,
          customer_name: res?.name,
          email: payload?.email,
        },
      });
    } catch (err) {
      throw err;
    }
  };


  
  const handleNewCustomerCall = async (customerDetails) => {
    try {
      setloading(true)

      const payload = {
        ...customerDetails,
        phone: customerDetails?.phone?.replace(/\s/g, ""),
        event_id: state.event_id,
        cookie: state.cookie || "",
        organizationId: state.organizationDetails?.id,
      };
      let res = await createCustomer(payload);
      updateState({
        ...state,
        miscellaneous: {
          ...state?.miscellaneous,
          newCustomerPage: false,
          error: "",
        },
        clientInfo: {
          ...state.clientInfo,
          isExistingCustomer: false,
          mobilephone: payload?.phone,
          address: {
            location_id: res?.userData?.locations[0]?.id,
            address_street: res?.userData?.address?.street,
            address_state: res?.userData?.address?.state,
            address_city: res?.userData?.address?.city,
            address_country: res?.userData?.address?.country,
            address_zip: res?.userData?.address?.zip,
          },
          customer_id:res?.userData?.id,
          customer_name: res?.userData?.name,
          email: payload?.email,
          followupleadid: res?.leadId,
        },

      });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    setloading(false)
  }, [state?.clientInfo]);


  const handleConfirmBooking = async () => {
    try {
      updateState({
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          loading: true,
        },
      });

      const jobItem = state?.additionalInfo?.jobItemToBook;

      const isMember = state?.clientInfo?.is_member;

      const formattedNotes = state?.problem?.questions?Object.entries(state?.problem?.questions)
      ?.map(([key, value]) => `Question: ${key}\nAnswer: ${value}`)
      .join("\n\n"):null;


      const customUrlData=state?.customUrlData
      if(customUrlData){
        const customUrlDataPayload={
          organizationId: state.organizationDetails?.id,
          cookie: state.cookie,
          eventId: state.event_id,
          customer: {
            ...state?.clientInfo,
            is_member: isMember,
          },
          businessUnit: {
            id: state.customUrlData.buId.id,
            name:state.customUrlData.buId.name,
          },
          jobType: {
            is_member: customUrlData?.dispatchFeeDetails?.member_is_active ?? false,
            st_member_job_id: customUrlData?.jobType.id ?? "",
            st_member_job_name: customUrlData?.jobType.name ?? "",
            st_non_member_job_id: customUrlData?.jobType.id ?? "",
            st_non_member_job_name: customUrlData?.jobType.name ?? "",
          },
          additionalQuestions: JSON.stringify(state?.problem?.questions),
          summary: state?.additionalInfo?.additionalInfo,
          priority: "Normal",
          arrivalWindowStart: state?.appointment?.arrivalWindowStart,
          arrivalWindowEnd: state?.appointment?.arrivalWindowEnd,
          date: state?.appointment?.date,
          windowStart: state?.appointment?.displayStartTime,
          windowEnd: state?.appointment?.displayEndTime,
          notes: formattedNotes,
          customUrlData:{
            tagIds:customUrlData?.tagIds,
            campaignData:customUrlData?.campaignData,
          },
          followupleadid: state?.clientInfo?.followupleadid,
        }
        await createBooking(customUrlDataPayload);
      }
      else{
        const bookingPayload = {
          organizationId: state.organizationDetails?.id,
          cookie: state.cookie,
          eventId: state.event_id,
          customer: {
            ...state?.clientInfo,
            is_member: isMember,
          },
          businessUnit: {
            id: jobItem?.st_business_unit_id?.length
              ? jobItem?.st_business_unit_id
              : "",
            name: jobItem?.st_business_unit_name?.length
              ? jobItem?.st_business_unit_name
              : "",
          },
          jobType: {
            is_member: jobItem?.is_member ?? false,
            st_member_job_id: jobItem?.st_member_job_id ?? "",
            st_member_job_name: jobItem?.st_member_job_name ?? "",
            st_non_member_job_id: jobItem?.st_non_member_job_id ?? "",
            st_non_member_job_name: jobItem?.st_non_member_job_name ?? "",
          },
          additionalQuestions: JSON.stringify(state?.problem?.questions),
          summary: state?.additionalInfo?.additionalInfo,
          priority: "Normal",
          arrivalWindowStart: state?.appointment?.arrivalWindowStart,
          arrivalWindowEnd: state?.appointment?.arrivalWindowEnd,
          date: state?.appointment?.date,
          windowStart: state?.appointment?.displayStartTime,
          windowEnd: state?.appointment?.displayEndTime,
          notes: formattedNotes,
          followupleadid: state?.clientInfo?.followupleadid,
        };
      console.log("booking payload")
      console.log(bookingPayload);
      await createBooking(bookingPayload);
      }
 
      setCurrentMainStep(currentMainStep + 1);
      setCurrentSubStep(currentSubStep + 1);
      // Uncomment belo
    } catch (err) {
      console.log(err);
    } finally {
      updateState({
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          loading: false,
        },
      });
    }
  };


  return (currentMainStep === 0 && currentSubStep !== null) ||
    (currentMainStep !== 0 && currentSubStep !== null) ? (
    <div className="steps_btn">
      <Button
        // className="steps_button back_button"
        className={`steps_button back_button ${
          !state.miscellaneous.loading ? "" : "clicked"
        }`}
        onClick={handleBackButton}
        disabled={state?.miscellaneous?.loading}
      >
        <svg
          className="button_icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="arrow-left-long"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
          ></path>
        </svg>
        <span className="button_text">Back</span>
      </Button>
      {(state?.customUrlData?currentMainStep === 3:currentMainStep === 4) ? (
        <Button
          className={`steps_button next_button ${
            state.isSubStepValid ? "" : "clicked"
          }`}
          onClick={handleConfirmBooking}
          disabled={state?.isSubStepValid ? false : true}
        >
          <svg
            className="button_icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M442.7 124.7L206.3 361.1 69.7 224.5c-9.4-9.4-24.6-9.4-34 0s-9.4 24.6 0 34l150.4 150.4c9.4 9.4 24.6 9.4 34 0L443 158.7c9.4-9.4 9.4-24.6 0-34z"
            ></path>
          </svg>

          <span className="button_text">Confirm Appointment</span>
        </Button>
      ) : null}
      {(state?.customUrlData?(currentMainStep === 3):(currentMainStep===1 || currentMainStep === 4)) ? null : (
        loading?<LoadingSpinner/>:  <Button
        className={`steps_button next_button ${
          state.isSubStepValid ? "" : "clicked"
        }`}
        onClick={handleNextbutton}
        loading={loading}
        disabled={loading || state?.isSubStepValid ? false : true}
      >
        <svg
          className="button_icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="arrow-right-long"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
          ></path>
        </svg>
        <span className="button_text">Next</span>
      </Button>
      )}
    </div>
  ) : null;
};

export default Navigation;