import {Steps} from 'antd'
import { paramSchedulerSteps, SchedulerSteps } from '../../../Utils/constants'

import './Stepper.css'

const Stepper = ({currentStep}) => {
  const params = new URLSearchParams(window.location.search);
  const paramsObject = {};
  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }
  return (
    <div className='appointment_stepper_wrapper'>
      <Steps
        className='appointmnt_setps'
        current={currentStep}
        labelPlacement="vertical"
        items={paramsObject?.customUrlCode?paramSchedulerSteps:SchedulerSteps}
      />
    </div>
  )
}

export default Stepper;