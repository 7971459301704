import { useEffect, useContext, useState } from "react";
import { Button, Image } from "antd";
import { AppContext } from "../../Context/AppContext";
import ExistingCustomer from "./ExistingCustomer";
import ChangeAddressStep from "./ExistingCustomer/ChangeAddressStep";
import NewCustomer from "./NewCustomer";
import AddressStep from "./NewCustomer/AddressStep";
import "./ClientInfo.css";

const ClientInfo = ({ currentSubStep, setCurrentSubStep, setTotalSteps }) => {
	const { state, updateState, resetState } = useContext(AppContext);
	const isExistingCustomer = state.clientInfo.isExistingCustomer;
	const InternalComponents = {
		isExistingCustomer: [
			{
				component: <ExistingCustomer />,
			},
			{
				component: <ChangeAddressStep />,
			},
		],
		isNewCustomer: [
			{ component: <NewCustomer /> },
			{ component: <AddressStep /> },
		],
	};

	useEffect(() => {
		const totalSteps = isExistingCustomer
			? InternalComponents?.isExistingCustomer?.length
			: InternalComponents?.isNewCustomer?.length;
		if (totalSteps - 1 > 0) {
			setTotalSteps((prev) => {
				return {
					...prev,
					clientInfo: { ...prev.clientInfo, totalSteps: totalSteps - 1 },
				};
			});
		} else {
			setTotalSteps((prev) => {
				return {
					...prev,
					clientInfo: { ...prev.clientInfo, totalSteps: 0 },
				};
			});
		}
	}, [isExistingCustomer]);

	const handleExistingCustomer = () => {
		updateState({
			...state,
			clientInfo: {
				...state.clientInfo,
				isExistingCustomer: true,
			},
		});
		setCurrentSubStep(0);
		// resetState();
	};

	const handleNewCustomer = () => {
		updateState({
			...state,
			clientInfo: {
				...state.clientInfo,
				isExistingCustomer: false,
			},
		});
		setCurrentSubStep(0);
		// resetState();
	};

	useEffect(() => {
		if (currentSubStep === null) {
			updateState({
				...state,
				clientInfo: {
					isExistingCustomer: true,
					mobilephone: "",
					address: "",
					customer_id: "",
					customer_name: "",
					email: "",
				},
				miscellaneous: {
					fullAddress: "",
					additionalQuestions: [], // available questions for a job
					newCustomerPage: false,
					appointmentReviewPage: false,
					agreementCheckbox: false,
					newCustomerData: {
						phone: "",
						email: "",
						name: "",
						street: "",
						city: "",
						state: null,
						zip: "",
					},
				},
			});
		}
	}, [currentSubStep]);

	return (
		<>
			{currentSubStep !== null ? (
				isExistingCustomer ? (
					InternalComponents.isExistingCustomer[currentSubStep]?.component
				) : (
					InternalComponents.isNewCustomer[currentSubStep]?.component
				)
			) : (
				<div className="InfoStep_otr">
					<div className="logo_img_container">
						<Image
							className="logo_img"
							src={state?.organizationDetails?.organization_logo?.url}
							preview={false}
						/>
					</div>
					<div className="action_otr">
						<Button
							className="theme_btn existing_btn"
							onClick={handleExistingCustomer}
						>
							I am an existing customer
						</Button>
						<Button className="theme_btn new_btn" onClick={handleNewCustomer}>
							I am a new customer
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default ClientInfo;
