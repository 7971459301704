import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../Context/AppContext.js";
import { TileButton } from "../../General/Button/index.tsx";
import { icons } from "../../../Utils/constants.js";
import "./Trade.css";

const Trade = ({ setCurrentSubStep }) => {
	const { state, updateState } = useContext(AppContext);
	const [tradeData, setTradeData] = useState();

	const handleItemClick = (selectedCategory, isSingleTrade = false) => {
		updateState({
			...state,
			isSubStepValid: true,
			problem: {
				...state.problem,
				trade: selectedCategory,
				servicetype: "",
				isSingleTrade
			},
		});
		setCurrentSubStep(1);
	};

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: false,
			miscellaneous: {
				...state.miscellaneous,
			},
		});

		const tradesExist = state.organizationDetails?.navigation_mapping?.length;
		const activeTrades = tradesExist ? state.organizationDetails?.navigation_mapping?.filter(({isActive}) => isActive) : 0

		if (activeTrades.length) {
			const {problem} = state;
			if(activeTrades.length === 1){
				handleItemClick(activeTrades[0], true);
			}else {
				setTradeData(activeTrades);
			}
		}

		if (state.problem.trade) {
			updateState({
				...state,
				isSubStepValid: true,
			});
		}
	}, []);

	return (
		<div className="Trade_otr">
			<h6 className="help_heading">
				What do you need help with?
				<hr />
			</h6>
			<div className="problem_card_otr">
				{tradeData?.map((trade, index) => {
					// if (!trade?.isActive) return null;
					// const tradeName = trade?.trade?.tradeName?.toLowerCase();
					const tradeName = trade?.trade?.name?.toLowerCase();
					console.log(state?.organizationDetails?.trades_logo)
					let tradeUrl=state?.organizationDetails?.trades_logo.filter((Logo)=>Logo.tradeName===trade?.trade?.name)[0]?.url;
					const TradeIcon = tradeUrl ? (
						<img  height={48} src={tradeUrl} alt={tradeName} className="trade-icon" />
					) : (
						icons[tradeName] && React.createElement(icons[tradeName])
					);
					return (
						<TileButton
						className="problem_card"
							key={index}
							onClick={() => handleItemClick(trade)}
						>
						<div className="trade_icon_otr">
							{TradeIcon}
						</div>
							<span
								className={`icon_text ${
									state.problem.trade?.trade?.id === trade?.trade?.id
										? "clicked"
										: ""
								}`}
							>
								{trade?.trade?.name}
							</span>
						</TileButton>
					);
				})}
			</div>
		</div>
	);
};

export default Trade;
