import React, { createContext, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const initialState = {
	isSubStepValid: false,

	// returned api data
	organizationDetails: null,
	existingCustomerDetails: null,

	//event_id
	event_id: uuidv4() || 0,
	cookie: null,

	// 5 scheduler steps
	clientInfo: {
		isExistingCustomer: true,
		mobilephone: "",
		address: "",
		customer_id: "",
		customer_name: "",
		email: "",
		is_member:"",
	},
	problem: {
		trade: "",
		servicetype: "",
		serviceObj: null,
		jobItem: "", // nav flow job item
		folderName: "",
		questions: null,
	},
	additionalInfo: {
		jobItemToBook: null, // job mapping's job
		additionalQuestion: null, // store selected additional question and option
		fee: 0,
		additionalInfo: "",
	},
	appointment: "",
	customUrlData:null,
	miscellaneous: {
		fullAddress: "",
		additionalQuestions: [], // available questions for a job
		newCustomerPage: false,
		appointmentReviewPage: false,
		agreementCheckbox: false,
		newCustomerPagewhatconverts: false,
		newCustomerData: {
			phone: "",
			email: "",
			name: "",
			street: "",
			city: "",
			state: null,
			zip: "",
		},
		loading: false,
		error: "",
	},
};

const AppContext = createContext();

const appReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_STATE":
			return { ...state, ...action.payload };
		case "RESET_ADDITIONALINFO":
			return {
				...state,
				additionalInfo: {
					...state.additionalInfo,
					additionalInfo: "",
				},
				miscellaneous: {
					...state.miscellaneous,
					agreementCheckbox: false,
				},
			};
		case "RESET_STATE":
			return {
				...initialState,
				organizationDetails: state?.organizationDetails,
			}; // Reset state to its initial values
		default:
			return state;
	}
};

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(appReducer, initialState);

	const updateState = (payload) => {
		dispatch({ type: "UPDATE_STATE", payload });
	};

	const resetAdditionalInfo = () => {
		dispatch({ type: "RESET_ADDITIONALINFO" });
	};

	const resetState = () => {
		dispatch({ type: "RESET_STATE" });
	};

	return (
		<AppContext.Provider
			value={{ state, updateState, resetAdditionalInfo, resetState }}
		>
			{children}
		</AppContext.Provider>
	);
};

export { AppContext, AppProvider };
