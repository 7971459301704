import { AirQuality, ElectricalInstallation, HvacInstallation, HvacRepair, PlumbingInstallation, Maintenance } from "./icons";

export const SchedulerSteps = [
	{ title: "Client Info" },
	{ title: "Problem" },
	{ title: "Description" },
	{ title: "Appointment" },
	{ title: "Confirm" },
];

export const paramSchedulerSteps=[
	{ title: "Client Info" },
	{ title: "Description" },
	{ title: "Appointment" },
	{ title: "Confirm" },
];

export const icons = {
	// trades
	plumbing: AirQuality,
	electrical: ElectricalInstallation,
	hvac: HvacInstallation,
	"heating & cooling": HvacInstallation,
	
 	// service types
	repair: HvacRepair,
	estimate: PlumbingInstallation,
	maintenance: Maintenance,
}



