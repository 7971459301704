import { useContext, useEffect, useState } from "react";
import { PrimaryInput } from "../../General/Input/index.tsx";

import "./NewCustomer.css";
import { AppContext } from "../../../Context/AppContext.js";

const NewCustomer = () => {
	const { state, updateState } = useContext(AppContext);

	const [inputErrors, setInputErrors] = useState({
		name: "",
		phone: "",
		email: "",
	});
	const isValidName = (name) => {
		const nameRegex = /^[a-zA-Z ]+$/;
		return nameRegex.test(name);
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return emailRegex.test(email);
	};

	const isValidPhoneNumber = (phone) => {
		const phoneRegex = /^\d{10}$/;
		const trimmedNumber = phone.replace(/\s/g, "");
		return phoneRegex.test(trimmedNumber);
	};

	const validateFields = (fieldName, value, labelText) => {
		if (!value || value.trim() === "") {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: `${labelText} is required`,
			}));
			return false;
		} else {
			setInputErrors((prevErrors) => ({
				...prevErrors,
				[fieldName]: "",
			}));

			if (fieldName === "name" && !isValidName(value)) {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					[fieldName]: "Invalid name",
				}));
				return false;
			}

			if (fieldName === "email" && !isValidEmail(value)) {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					[fieldName]: "Invalid email address",
				}));
				return false;
			}

			if (fieldName === "phone" && !isValidPhoneNumber(value)) {
				setInputErrors((prevErrors) => ({
					...prevErrors,
					[fieldName]: "Phone number must be 10 digits",
				}));
				return false;
			}

			return true;
		}
	};

	const handleInputChange = (field, value, labelText) => {
		if (field === "phone") {
			// value = value.slice(0, 10);
			value = value.replace(/\D/g, "");
			value = value
				.replace(/(\d{3})(\d{3})?(\d{0,4})?/, (_, p1, p2, p3) => {
					let result = p1;
					if (p2) result += " " + p2;
					if (p3) result += " " + p3;
					return result;
				})
				.slice(0, 12);
		}

		updateState({
			...state,
			miscellaneous: {
				...state.miscellaneous,
				newCustomerPagewhatconverts: true,
				newCustomerData: {
					...state.miscellaneous.newCustomerData,
					[field]: value,
				},
			},
		});

		validateFields(field, value, labelText);
	};

	useEffect(() => {
		updateState({
			...state,
			isSubStepValid: false,
			miscellaneous: {
				...state?.miscellaneous,
				newCustomerPage: false,
				newCustomerPagewhatconverts:true,
				newCustomerData: {
					...state.miscellaneous.newCustomerData,
					organizationId: state?.organizationDetails?.id,
				},
			},
		});
	}, []);

	useEffect(() => {
		const checkFields = ["name", "email", "phone"];
		const isFormValid =
			Object.values(inputErrors || {}).every((value) => value === "") &&
			checkFields.every(
				(field) => state?.miscellaneous?.newCustomerData[field]
			);

		updateState({
			...state,
			isSubStepValid: isFormValid,
		});
	}, [inputErrors, state.miscellaneous.newCustomerData]);

	return (
		<>
			<div className="steps_content_inr NewCustomer_otr">
				<h6 className="customer_heading">
					New Customer
					<hr />
				</h6>
				<p className="customer_desc">Please enter your details below</p>
				<div className="inputs_otr">
					<PrimaryInput
						className={`theme_input ${inputErrors?.name ? "error-border" : ""}`}
						labelText="First name and last name"
						prefix={
							<svg
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 1.75C12.3828 1.75 14.375 3.74219 14.375 6.125C14.375 8.54688 12.3828 10.5 10 10.5C7.57812 10.5 5.625 8.54688 5.625 6.125C5.625 3.74219 7.57812 1.75 10 1.75ZM15 14.25C17.0312 14.25 18.75 15.9688 18.75 18V19.25H1.25V18C1.25 15.9688 2.92969 14.25 5 14.25C8.32031 14.25 7.61719 14.875 10 14.875C12.3438 14.875 11.6406 14.25 15 14.25ZM10 0.5C6.875 0.5 4.375 3.03906 4.375 6.125C4.375 9.25 6.875 11.75 10 11.75C13.0859 11.75 15.625 9.25 15.625 6.125C15.625 3.03906 13.0859 0.5 10 0.5ZM15 13C11.3672 13 12.2266 13.625 10 13.625C7.77344 13.625 8.59375 13 5 13C2.22656 13 0 15.2656 0 18V19.25C0 19.9531 0.546875 20.5 1.25 20.5H18.75C19.4141 20.5 20 19.9531 20 19.25V18C20 15.2656 17.7344 13 15 13Z"
									fill="#1F2327"
								></path>
							</svg>
						}
						placeholder="First name and last name"
						value={state.miscellaneous.newCustomerData?.name}
						onChange={(value) =>
							handleInputChange("name", value, "Customer name")
						}
						error={inputErrors?.name}
					/>
					<PrimaryInput
						className={`theme_input ${
							inputErrors?.phone ? "error-border" : ""
						}`}
						labelText="Phone number"
						prefix={
							<svg
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.2578 14.25L15 12.4141C14.8828 12.375 14.6484 12.3359 14.5312 12.3359C14.2188 12.3359 13.7891 12.5312 13.5938 12.7656L11.875 14.875C9.57031 13.7422 6.75781 10.9688 5.625 8.625L7.73438 6.90625C7.96875 6.71094 8.16406 6.28125 8.16406 5.96875C8.16406 5.85156 8.125 5.61719 8.08594 5.5L6.25 1.24219C6.09375 0.851562 5.58594 0.539062 5.15625 0.539062C5.07812 0.539062 4.92188 0.539062 4.88281 0.539062L0.9375 1.47656C0.390625 1.59375 0 2.10156 0 2.64844C0 12.5312 8.00781 20.5 17.8516 20.5C18.3984 20.5 18.9062 20.1094 19.0625 19.5625L19.9609 15.6172C19.9609 15.5781 19.9609 15.4219 19.9609 15.3438C19.9609 14.9141 19.6484 14.4062 19.2578 14.25ZM17.8125 19.25C8.67188 19.25 1.25 11.8281 1.25 2.6875L5.11719 1.78906L6.91406 5.96875L4.0625 8.3125C5.97656 12.3359 8.16406 14.5234 12.2266 16.4375L14.5312 13.5859L18.7109 15.3828L17.8125 19.25Z"
									fill="#1F2327"
								></path>
							</svg>
						}
						placeholder="Phone number"
						value={state.miscellaneous.newCustomerData?.phone}
						onChange={(value) => {
							handleInputChange("phone", value, "Phone number");
						}}
						error={inputErrors?.phone}
					/>
					<PrimaryInput
						className={`theme_input ${
							inputErrors?.email ? "error-border" : ""
						}`}
						labelText="Email address"
						prefix={
							<svg
								width="20"
								height="14"
								viewBox="0 0 20 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M18.125 0H1.875C0.820312 0 0 0.859375 0 1.875V13.125C0 14.1797 0.820312 15 1.875 15H18.125C19.1406 15 20 14.1797 20 13.125V1.875C20 0.859375 19.1406 0 18.125 0ZM1.875 1.25H18.125C18.4375 1.25 18.75 1.5625 18.75 1.875V3.51562C17.8906 4.21875 16.6406 5.23438 12.8516 8.24219C12.1875 8.78906 10.8984 10.0391 10 10C9.0625 10.0391 7.77344 8.78906 7.10938 8.24219C3.32031 5.23438 2.07031 4.21875 1.25 3.51562V1.875C1.25 1.5625 1.52344 1.25 1.875 1.25ZM18.125 13.75H1.875C1.52344 13.75 1.25 13.4766 1.25 13.125V5.11719C2.10938 5.85938 3.51562 6.99219 6.32812 9.21875C7.14844 9.88281 8.55469 11.2891 10 11.25C11.4062 11.2891 12.8125 9.88281 13.6328 9.21875C16.4453 6.99219 17.8516 5.85938 18.75 5.11719V13.125C18.75 13.4766 18.4375 13.75 18.125 13.75Z"
									fill="#1F2327"
								></path>
							</svg>
						}
						placeholder="Email address"
						value={state.miscellaneous.newCustomerData?.email}
						onChange={(value) =>
							handleInputChange("email", value, "Email Address")
						}
						error={inputErrors?.email}
					/>
				</div>
				<p className="customer_note">
					Please Note:
					<span className="note_text">
						We may contact you by phone, email, or SMS about this visit.
					</span>
				</p>
			</div>
		</>
	);
};

export default NewCustomer;