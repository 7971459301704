import axios from "./axios";

export const getTimeSlots = async (timeslotPaylod) => {
	try {
		const { data } = await axios.post(
			`/scheduler/get/timeSlots`,
			timeslotPaylod,
			{
				timeout: 30000, // Timeout in milliseconds (30 seconds)
			}
		);
		return data || "";
	} catch (err) {
		console.log(err);
	}
};
